import React from "react"
import { graphql, Link } from 'gatsby'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'
import tw from "twin.macro"

const Columns = styled.div`
  column-count: 1;
  ${tw`md:pl-4`}


  @media only screen and (min-width: 768px) {
    column-count: 2;
    break-inside: avoid-column;
    page-break-inside: avoid;

    a {
      display: block;
    }
    
  }
`;


const LandingPage = (props) => {

  const grundlagen = props.data.grundlagen.edges;
  const blog = props.data.blog.edges;

  return (
    <Shell className="">
      <Container>

        <div className="flex md:flex-row flex-wrap mt-12 md:mt-28">
          
          <div className="w-full md:w-1/3 pr-3  md:sticky md:top-0">
            <h1 className="text-3xl md:text-5xl font-bold pb-3 text-gray-900">E-Learning, Daten und KI</h1>

            <h2 className="text-xl md:text-2xl font-bold italic text-gray-600">von Christian Burkhart</h2>
          </div>

          <div className="w-full md:w-2/3 md:pl-5 mt-12 md:mt-0">
            <div className="mb-12">
              <h3 className="font-bold text-lg md:text-2xl">Über diese Webseite</h3>
              <Columns>
                <Link to ="/ueberdieseite" className="block text-gray-800 text-lg md:text-xl pt-1 md:pt-3 hover:underline">Über diese Seite</Link>
                <Link to ="/autor" className="block text-gray-800 text-lg md:text-xl pt-1 md:pt-3 hover:underline">Der Autor</Link>
                <Link to ="/rechtliches" className="block text-gray-800 text-lg md:text-xl pt-1 md:pt-3 hover:underline">Rechtliches</Link>
              </Columns>
            </div>

            <div className="mb-12">
              <h3 className="font-bold text-lg md:text-2xl">Grundlagen des E-Learning</h3>
              <p className="text-lg text-gray-600 py-2 md:pl-4">Eine digitales Buch über die wissenschaftlichen Grundlagen der Gestaltung von E-Learning Kursen. Das Buch entstand als Begleitmaterial des Seminars Grundlagen des E-Learning an der Universität Freiburg.</p>
              <Columns>
                {grundlagen.map((e) => {
                  return <Link key={e.node.id} to={e.node.frontmatter.slug} className="text-gray-800 text-lg md:text-xl pt-1 md:pt-3 block hover:text-black hover:underline">{e.node.frontmatter.title}</Link>
                })}
              </Columns>
            </div>


            <div className="mb-12">
              <h3 className="font-bold text-lg md:text-2xl">Daten, KI und Bildung</h3>
              <p className="text-lg text-gray-600 py-2 md:pl-4">Work in progress ...</p>
              <Columns></Columns>
            </div>

            <div className="mb-12">
              <h3 className="font-bold text-lg md:text-2xl">Blog</h3>
              {/* <Columns> */}
                {blog.map((e) => {
                    return <Link key={e.node.id} to={e.node.frontmatter.slug} className="md:pl-4 text-gray-800 text-lg md:text-xl pt-1 md:pt-3 hover:text-black hover:underline inline-block">{e.node.frontmatter.title}</Link>
                  })}
              {/* </Columns> */}
            </div>

          </div>
        </div>

      </Container>
    </Shell>
  )
}

export default LandingPage



export const pageQuery = graphql`
  query MyQuery {
    grundlagen: allMdx(
      filter: {frontmatter: {content: {eq: "grundlagen"}}}
      sort: {fields: [frontmatter___chapter], order: ASC}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            chapter
          }
        }
      }
    }
    blog: allMdx(
      filter: {frontmatter: {content: {eq: "blog"}}}
      sort: {fields: [frontmatter___date], order: ASC}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`;